import React, { Component } from 'react'

class TaskTimer extends Component {
  constructor (props) {
    super(props)
    this.state = {
      running: 0
    }
    this.ts = props.ts
  }

  componentDidMount () {
    this.checkAndSetTimer()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.task.state !== this.props.task.state ||
            prevProps.task.updatedAt !== this.props.task.updatedAt) {
      this.checkAndSetTimer()
    }
  }

  checkAndSetTimer () {
    this.calcRunning()
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
    if (this.active()) {
      this.timer = setInterval(() => this.calcRunning(), 100)
    }
  }

  calcRunning () {
    let running
    if (this.props.task.startedAt === undefined) {
      running = 0
    } else if (this.active()) {
      running = Math.max(0, Math.floor((this.ts.now() - new Date(this.props.task.startedAt).getTime()) / 1000))
    } else if (this.finished() && this.props.task.finishedAt !== undefined) {
      running = Math.floor((new Date(this.props.task.finishedAt).getTime() - new Date(this.props.task.startedAt).getTime()) / 1000)
    } else if (this.finished() && this.props.task.expireAt !== undefined) {
      // If task has expired but was not stopped, let's show running time until expire
      running = Math.floor((new Date(this.props.task.expireAt).getTime() - new Date(this.props.task.startedAt).getTime()) / 1000)
    } else {
      running = 0
    }
    if (this.state.running !== running) {
      this.setState({ running })
    }
  }

  active () {
    const { state } = this.props.task
    return state === 'started' || state === 'sent_cases'
  }

  finished () {
    const { state } = this.props.task
    return state === 'finished' || state === 'deleted'
  }

  formatTime (seconds) {
    const minutes = Math.floor(seconds / 60)
    seconds = seconds % 60
    return `${minutes}:${seconds > 9 ? seconds : `0${seconds}`}`
  }

  render () {
    const { running } = this.state

    return (
      <div>
        <strong>{ this.formatTime(running) }</strong>
      </div>
    )
  }
}

export default TaskTimer
