import React, { Component } from 'react'
import io from 'socket.io-client'
import { TaskApi, setToken } from '../api'
import TaskComponent from '../components/Task'
import './_task.scss'

class Task extends Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null,
      loading: false,
      task: { token: props.match.params.taskId },
      isManager: false
    }
    this.setFreshTaskVersion = this.setFreshTaskVersion.bind(this)
    this.pullTask = this.pullTask.bind(this)
    // Set auth token
    const authToken = window.localStorage.getItem(`task-${props.match.params.taskId}`)
    if (authToken) {
      this.authToken = authToken
      setToken(authToken)
    }
  }

  componentDidMount () {
    const taskId = this.state.task.token
    this.setState({ loading: true })
    Promise.all([this.pullTask(), this.checkAccess()])
      .catch((error) => {
        this.setState({ error })
      })
      .finally(() => {
        this.setState({ loading: false })
      })

    const socket = io(process.env.SOCKET_URL)
    const socketMessage = { taskId }
    if (this.authToken !== undefined) {
      socketMessage.authToken = this.authToken
    }
    socket.on('connect', () => socket.emit('room', socketMessage))
    socket.on('task', ({ task }) => this.setFreshTaskVersion(task))
  }

  pullTask () {
    return TaskApi.get(this.state.task.token)
      .then(({ data }) => {
        this.setFreshTaskVersion(data.task)
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          this.setState({ task: null })
          return
        }
        this.setState({ error })
      })
  }

  checkAccess () {
    return TaskApi.checkAccess(this.state.task.token)
      .then(({ data }) => {
        if (data.access) {
          this.setState({ isManager: true })
        }
      })
  }

  setFreshTaskVersion (task) {
    const oldTask = this.state.task
    if (!oldTask.updatedAt || oldTask.updatedAt < task.updatedAt || oldTask.state !== task.state) {
      this.setState({ task })
    }
  }

  render () {
    const { error, loading, task, isManager } = this.state
    return (
      <div className='page container task'>
        <div className='row pt-5'>
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error while loading task, try refreshing the page</div>
          ) : !task ? (
            <div>Task not found</div>
          ) : (
            <TaskComponent task={task} isManager={isManager} updateTask={this.setFreshTaskVersion} pullTask={this.pullTask} />
          )}
        </div>
      </div>
    )
  }
}

export default Task
