import 'core-js/es6/promise'
import 'core-js/fn/promise'
import 'core-js/es6/map'
import 'core-js/es6/set'
import 'react-app-polyfill/ie11'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()

// Clear old tokens
setTimeout(() => {
  const toRemove = []
  for (let i = 0; i < window.localStorage.length; i++) {
    let key = window.localStorage.key(i)
    if (key.startsWith('expire-')) {
      if (parseInt(window.localStorage.getItem(key)) < Date.now()) {
        toRemove.push(key)
        toRemove.push(`task-${key.substring('expire-'.length)}`)
      }
    }
  }
  toRemove.forEach(item => window.localStorage.removeItem(item))
}, 30000)
