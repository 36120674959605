import React from 'react'
import Linkify from 'react-linkify';
import AutoPull from './AutoPull'
import TaskTimer from './TaskTimer'
import ControlButtons from './ControlButtons'
import { TimeSyncContext } from '../TimeSyncContext'

const componentDecorator = (href, text, key) => (
  <a href={href} key={key} rel="noopener noreferrer" target="_blank">
    {text}
  </a>
);

export default ({ task, isManager, updateTask, pullTask }) => (
  <TimeSyncContext.Consumer>
    { ts => (
      <React.Fragment>
        <div className='col-md-9 ml-auto mr-auto order-1 order-md-1 order-sm-2'>
          { task.title && (
            <h2 className='pb-3 pl-3'>{ task.title }</h2>
          )}

          { task.state === 'deleted' && (
            <p>
              Task has been deleted
              { isManager && (
                <strong>. Details are visible only to screener</strong>
              ) }
            </p>
          )}

          { task.task && (task.state !== 'deleted' || isManager) && (
            <div className='col-12 p-4 mb-4 card'>
              <h3>Task Description</h3>
              <pre>
                <Linkify
                  componentDecorator={componentDecorator}
                >
                  {task.task}
                </Linkify>
              </pre>
            </div>
          )}

          { task.cases && task.testShown && (
            <div className='col-12 p-4 mb-4 card'>
              <h3 className='pt-2'>Test cases</h3>
              <pre>
                <Linkify
                  componentDecorator={componentDecorator}
                >
                  {task.cases}
                </Linkify>
              </pre>
            </div>
          )}

          { task.notes && (
            <div className='col-12 p-4 mb-4 card'>
              <h3 className='pt-2'>Visible only to screener</h3>
              <pre>
                <Linkify
                  componentDecorator={componentDecorator}
                >
                  {task.notes}
                </Linkify>
              </pre>
            </div>
          )}

        </div>

        <div className='col-md-3 pl-md-5 pr-md-3 pb-5 order-md-2 order-sm-1'>
          <div className='control'>

            <h3 className='timer'><TaskTimer task={task} ts={ts} /></h3>

            { isManager && (<ControlButtons task={task} updateTask={updateTask} pullTask={pullTask} />) }

            <AutoPull task={task} ts={ts} pullTask={pullTask} />

          </div>
        </div>
      </React.Fragment>
    )}
  </TimeSyncContext.Consumer>
)
