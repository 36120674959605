import React from 'react'
import { Link } from 'react-router-dom'

export default (props) => (
  <React.Fragment>

    <nav className='navbar navbar-expand navbar-dark bg-primary pt-4 px-0'>
      <div className='container'>
        <Link to='/' className='navbar-brand'>
          <img src='/images/logo-white.svg' className='mr-2' alt='ShareTask.io - the ultimate tool to conduct online interviews with developers' />
          ShareTask.io
        </Link>
        <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNavDropdown'
          aria-controls='navbarNavDropdown' aria-expanded='false' aria-label='Toggle navigation'>
          <span className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='navbarNavDropdown'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <a className='nav-link' href='/#'>Home</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/#our-services'>Our Services</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/#contact-us'>Early access</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    { props.children }

  </React.Fragment>
)
