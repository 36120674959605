import React, { Component } from 'react'
import { TaskApi } from '../api'

class ControlButtons extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      deleteConfirm: false
    }

    this.action.bind(this)
  }

  action (taskId, apiPromise) {
    this.setState({ loading: true })
    apiPromise(taskId)
      .then(({ data }) => {
        this.props.updateTask(data.task)
      })
      .catch(error => {
        // If manager got 403 - probably the task has expired and we should just render it
        if (error.response && error.response.status === 403) {
          this.props.pullTask()
        }
        console.log(error)
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render () {
    const { token: taskId, state, cases, testShown } = this.props.task
    const { loading, deleteConfirm } = this.state

    return (
      <React.Fragment>
        { state === 'new' && (
          <button className='btn btn-primary btn-block btn-lg' type='button'
            disabled={loading}
            onClick={() => this.action(taskId, TaskApi.start)}
          >
            Start Timer
          </button>
        )}

        { state === 'started' && (
          <React.Fragment>
            { cases && testShown !== true && (
              <button className='btn btn-primary btn-block btn-lg' type='button'
                disabled={loading}
                onClick={() => this.action(taskId, TaskApi.sendCases)}
              >
              Open test cases
              </button>
            )}
            <button className='btn btn-danger btn-block btn-lg' type='button'
              disabled={loading}
              onClick={() => this.action(taskId, TaskApi.finish)}
            >
            Stop
            </button>
          </React.Fragment>
        )}

        { state === 'sent_cases' && (
          <button className='btn btn-danger btn-block btn-lg' type='button'
            disabled={loading}
            onClick={() => this.action(taskId, TaskApi.finish)}
          >
          Stop
          </button>
        )}

        { state !== 'deleted' && !deleteConfirm && (
          <button className='btn btn-secondary btn-block btn-lg' type='button'
            disabled={loading}
            onClick={() => this.setState({ deleteConfirm: true })}
          >
        Delete
          </button>
        )}

        { deleteConfirm && (
          <React.Fragment>
            <button className='btn btn-secondary btn-block btn-lg' type='reset'
              disabled={loading}
              onClick={() => this.setState({ deleteConfirm: false })}
            >
            Cancel
            </button>
            <button className='btn btn-danger btn-block btn-lg' type='button'
              disabled={loading}
              onClick={() => {
                this.action(taskId, TaskApi.delete)
                this.setState({ deleteConfirm: false })
              }}
            >
            Confirm Delete
            </button>
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }
}

export default ControlButtons
