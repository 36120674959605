import React, { Component } from 'react'
import { EaApi } from '../api'

class EarlyAccess extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      loading: false,
      submitted: false
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleSubmit (event) {
    this.setState({ loading: true })
    EaApi.send(this.state.email, this.state.name)
      .finally(() => {
        this.setState({
          name: '',
          email: '',
          loading: false,
          submitted: true
        })
      })
    event.preventDefault()
  }

  handleInputChange (event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  render () {
    const { name, email, loading, submitted } = this.state
    return (
      <div id='contact-us' className='contact section-invert py-4'>
        <h3 className='section-title text-center m-5'>Early access</h3>
        <div className='container py-4'>
          <div className='row justify-content-md-center px-4'>
            <div className='contact-form col-sm-12 col-md-10 col-lg-7 p-4 mb-4 card'>
              { !submitted ? (
                <form onSubmit={this.handleSubmit}>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label htmlFor='name'>Full Name</label>
                        <input type='string' name='name' className='form-control'
                          id='name'
                          placeholder='Enter your full name' required
                          value={name}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div className='form-group'>
                        <label htmlFor='email'>Email address</label>
                        <input type='email' name='email' className='form-control'
                          id='email'
                          placeholder='Enter your email address' required
                          value={email}
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <input className='btn btn-primary btn-pill d-flex ml-auto mr-auto' type='submit' value='Get early access' disabled={loading} />
                </form>

              ) : (
                <p className='pb-5'>We will notify you soon!</p>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default EarlyAccess
