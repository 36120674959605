import React, { Component } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import Layout from './components/Layout'
import Index from './pages/Index'
import New from './pages/New'
import Task from './pages/Task'
import './app.scss'
import { create } from 'timesync'
import { TimeSyncContext } from './TimeSyncContext'

const ts = create({
  server: '/api/v1/timesync'
})

class App extends Component {
  render () {
    return (
      <TimeSyncContext.Provider value={ts}>
        <Router>
          <Switch>
            <Route exact path='/' component={Index} />
            <Route component={AppRoutes} />
          </Switch>
        </Router>
      </TimeSyncContext.Provider>
    )
  }
}

function AppRoutes () {
  return (
    <Layout>
      <Switch>
        <Route path='/new' component={New} />
        <Route path='/t' component={Tasks} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  )
}

function Tasks ({ match }) {
  return (
    <Route path={`${match.path}/:taskId`} component={Task} />
  )
}

function NotFound () {
  return (
    <div className='container py-4'>
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  )
}

export default App
