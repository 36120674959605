import React, { Component } from 'react'

const FormRow = (props) => (
  <div className='row'>
    <div className='col'>
      <div className='form-group'>
        { props.children }
      </div>
    </div>
  </div>
)

class New extends Component {
  constructor (props) {
    super(props)
    this.state = { token: '' }
    this.changeToken = this.changeToken.bind(this)
    this.savedTokenKey = 'saved-token'
  }

  componentDidMount () {
    const savedToken = window.localStorage.getItem(this.savedTokenKey)
    if (savedToken) {
      this.setState({ token: savedToken })
    }
  }

  changeToken (event) {
    this.setState({ token: event.target.value })
    window.localStorage.setItem(this.savedTokenKey, event.target.value)
  }

  render () {
    return (
      <div className='container py-4'>
        <div className='row justify-content-md-center px-4'>
          <div className='col-sm-12 col-lg-10 p-4 mb-4 card'>
            <form action='/api/v1/tasks/web' method='POST'>
              <input type='hidden' name='token' value='bzt3mse0bcbphns62ivp9r11odo8p62j' />

              <FormRow>
                <label htmlFor='title'>Title</label>
                <input id='title' className='form-control mb-4' placeholder='Enter title (optional)' name='title' />
              </FormRow>

              <FormRow>
                <label htmlFor='task'>Task</label>
                <textarea id='task' className='form-control mb-4' rows='8' placeholder='Enter task description...' name='task' />
              </FormRow>

              <FormRow>
                <label htmlFor='cases'>Test Cases</label>
                <textarea id='cases' className='form-control mb-4' rows='8' placeholder='Test cases' name='cases' />
              </FormRow>

              <FormRow>
                <label htmlFor='token'>Token</label>
                <input id='token' className='form-control mb-4' placeholder='Enter token (required)'
                  name='token'
                  value={this.state.token}
                  onChange={this.changeToken}
                />
              </FormRow>

              <input className='btn btn-primary btn-pill d-flex ml-auto mr-auto' type='submit' value='Share Task' />
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default New
