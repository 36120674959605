import TaskApi from './task'
import EaApi from './ea'
import axios from 'axios'

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.defaults.headers.post['Content-Type'] = 'application/json'

const setToken = function (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export { TaskApi, EaApi, setToken }
