import React, { Component } from 'react'
import EarlyAccess from '../components/EarlyAccess'
import './_index.scss'

class Index extends Component {
  render () {
    return (
      <div className='sharetask-landing-page'>
        <div className='welcome d-flex justify-content-center flex-column'>
          <div className='container'>
            <nav className='navbar navbar-expand-lg navbar-dark pt-4 px-0'>
              <a className='navbar-brand' href='/#'>
                <img src='images/logo-white.svg' className='mr-2' alt='ShareTask.io - the ultimate tool to conduct online interviews with developers' />
              ShareTask.io
              </a>
              <button className='navbar-toggler' type='button' data-toggle='collapse' data-target='#navbarNavDropdown' aria-controls='navbarNavDropdown' aria-expanded='false' aria-label='Toggle navigation'>
                <span className='navbar-toggler-icon' />
              </button>
              <div className='collapse navbar-collapse' id='navbarNavDropdown'>
                <ul className='navbar-nav'>
                  <li className='nav-item active'>
                    <a className='nav-link' href='/#'>Home <span className='sr-only'>(current)</span></a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='/#our-services'>Our Services</a>
                  </li>
                  <li className='nav-item'>
                    <a className='nav-link' href='/#contact-us'>Early access</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>

          <div className='inner-wrapper mt-auto mb-auto container'>
            <div className='row'>
              <div className='col-md-7'>
                <h1 className='welcome-heading display-4 text-white'>ShareTask.io</h1>
                <p className='text-white'>The ultimate tool to conduct online interviews with developers.</p>
                <a href='#our-services' className='btn btn-lg btn-outline-white btn-pill align-self-center'>Learn More</a>
              </div>
            </div>
          </div>
        </div>

        <div id='our-services' className='our-services section py-4'>
          <h3 className='section-title text-center my-5'>How we can help</h3>
          <div className='features py-4 mb-4'>
            <div className='container'>
              <div className='row'>
                <div className='feature py-4 col-md-6 d-flex'>
                  <div className='icon text-primary mr-3'><i className='fas fa-brain' /></div>
                  <div className='px-4'>
                    <h5>Choose desired level</h5>
                    <p>Do you interview a senior, middle or junior web-developer? We help to validate any level.</p>
                  </div>
                </div>
                <div className='feature py-4 col-md-6 d-flex'>
                  <div className='icon text-primary mr-3'><i className='fas fa-share' /></div>
                  <div className='px-4'>
                    <h5>Sharing</h5>
                    <p>Easily share the task with the candidate. Sharing experience is smooth and polished during thousands of interviews.</p>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='feature py-4 col-md-6 d-flex'>
                  <div className='icon text-primary mr-3'><i className='fas fa-code' /></div>
                  <div className='px-4'>
                    <h5>Programming</h5>
                    <p>Let the candidate solve your task and write some code.</p>
                  </div>
                </div>
                <div className='feature py-4 col-md-6 d-flex'>
                  <div className='icon text-primary mr-3'><i className='fas fa-tachometer-alt' /></div>
                  <div className='px-4'>
                    <h5>Measure result</h5>
                    <p>You will get explicit details how to measure the results even if you don't have technical background.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <EarlyAccess />

      </div>
    )
  }
}

export default Index
