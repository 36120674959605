import React, { Component } from 'react'

class AutoPull extends Component {
  constructor (props) {
    super(props)
    this.state = {
      running: 0
    }
    this.ts = props.ts
  }

  componentDidMount () {
    this.checkAndSetTimer()
  }

  componentDidUpdate (prevProps) {
    this.checkAndSetTimer()
  }

  checkAndSetTimer () {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this.timeout = null
    }
    const { task, pullTask } = this.props
    if (task.state !== 'deleted') {
      const expireIn = new Date(task.expireAt).getTime() - this.ts.now()
      this.timeout = setTimeout(() => pullTask(), Math.max(expireIn, 1000))
    }
  }

  render () {
    return (<React.Fragment />)
  }
}

export default AutoPull
