import axios from 'axios'

class TaskApi {
  static get (taskId) {
    return axios.get(`v1/tasks/${taskId}`)
  }

  static checkAccess (taskId) {
    return axios.get(`v1/tasks/${taskId}/access`)
  }

  static start (taskId) {
    return axios.patch(`v1/tasks/${taskId}/start`)
  }

  static sendCases (taskId) {
    return axios.patch(`v1/tasks/${taskId}/sendCases`)
  }

  static finish (taskId) {
    return axios.patch(`v1/tasks/${taskId}/finish`)
  }

  static delete (taskId) {
    return axios.patch(`v1/tasks/${taskId}/delete`)
  }
}

export default TaskApi
